import {Button} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {Dialog} from '@primer/react/experimental'
import {useRef} from 'react'
import {XIcon} from '@primer/octicons-react'

interface ImmutableActionsHasUnmigratableTagsDialogProps {
  releaseTags: string[]
  nonSemverTags: string[]
  setMigrationDialogOpen: (open: boolean) => void
  setHasUnmigratableTagsDialogOpen: (open: boolean) => void
}

export function ImmutableActionsHasUnmigratableTagsDialog({
  releaseTags,
  nonSemverTags,
  setMigrationDialogOpen,
  setHasUnmigratableTagsDialogOpen,
}: ImmutableActionsHasUnmigratableTagsDialogProps) {
  const returnFocusRef = useRef(null)
  const MAX_INVALID_TAGS_SHOWN = 100

  return (
    <Dialog
      returnFocusRef={returnFocusRef}
      onClose={() => setHasUnmigratableTagsDialogOpen(false)}
      width="large"
      aria-labelledby="header"
      title={<div data-testid="ia-unmigratable-tags-title">Non-semantic version tags found</div>}
      renderFooter={() => {
        return (
          <Dialog.Footer>
            <Button type="button" onClick={() => setHasUnmigratableTagsDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                setHasUnmigratableTagsDialogOpen(false)
                setMigrationDialogOpen(true)
              }}
            >
              Continue anyways
            </Button>
          </Dialog.Footer>
        )
      }}
    >
      <div>
        <div className="mb-2">
          {nonSemverTags.length > 0 && (
            <p>
              <strong>
                {nonSemverTags.length} out of {releaseTags.length} {pluralize('tag', releaseTags.length)}
              </strong>{' '}
              cannot be recreated because they did not follow semantic versioning at the time of their release. To
              include these tags, re-release these versions following semantic versioning. You can still continue with
              the migration process without re-releasing these versions, but they will not be recreated as immutable
              package versions.
            </p>
          )}
          {nonSemverTags.length > MAX_INVALID_TAGS_SHOWN && (
            <p>Only the first {MAX_INVALID_TAGS_SHOWN} invalid tags are listed.</p>
          )}
        </div>
        {nonSemverTags.length > 0 && (
          <div>
            <ul className="list-style-none">
              {nonSemverTags.slice(0, MAX_INVALID_TAGS_SHOWN).map(tag => (
                <li key={tag}>
                  <Octicon icon={XIcon} className="mr-1" sx={{color: 'danger.fg'}} />
                  {tag}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Dialog>
  )
}

function pluralize(word: string, count: number) {
  return `${word}${count > 1 ? 's' : ''}`
}

try{ ImmutableActionsHasUnmigratableTagsDialog.displayName ||= 'ImmutableActionsHasUnmigratableTagsDialog' } catch {}