import React, {useEffect, useMemo} from 'react'

import {AnalyticsProvider} from '@github-ui/analytics-provider'
import type {FileOverviewPayload} from '@github-ui/code-view-types'
import {CurrentRepositoryProvider} from '@github-ui/current-repository'
import {AppPayloadContext} from '@github-ui/react-core/use-app-payload'
// eslint-disable-next-line no-restricted-imports
import {ScreenSize, ScreenSizeProvider} from '@github-ui/screen-size'
import {AllShortcutsEnabledProvider} from '@github-ui/code-view-shared/contexts/AllShortcutsEnabledContext'
import {CodeViewBannersProvider} from '@github-ui/code-view-shared/contexts/CodeViewBannersContext'
import {CurrentTreeProvider} from '@github-ui/code-view-shared/contexts/CurrentTreeContext'
import {FileQueryProvider} from '@github-ui/code-view-shared/contexts/FileQueryContext'
import {FilesPageInfoProvider} from '@github-ui/code-view-shared/contexts/FilesPageInfoContext'
import {useFilePagePayload} from '@github-ui/code-view-shared/hooks/use-file-page-payload'
import {CurrentUserProvider} from '@github-ui/current-user'
import {useCanonicalObject} from '@github-ui/code-view-shared/hooks/use-canonical-object'

import OverviewContent from './components/OverviewContent'

export function ReposOverview({
  initialPayload,
  appPayload,
}: {
  initialPayload: FileOverviewPayload
  appPayload: unknown
}) {
  const payload = useFilePagePayload(initialPayload) as FileOverviewPayload

  const [repo] = React.useState(initialPayload?.repo)
  const [user] = React.useState(initialPayload?.currentUser)
  const refInfo = useCanonicalObject(payload.refInfo)

  const appName = 'react-code-view-overview'
  const category = ''
  const metadata = useMemo(() => ({}), [])

  useEffect(() => {
    const footer = document.querySelector('.footer')
    if (footer) {
      footer.querySelector('.mt-6')?.classList.replace('mt-6', 'mt-0')
      footer.querySelector('.border-top')?.classList.remove('border-top')
    }
  }, [])

  return (
    <AppPayloadContext.Provider value={appPayload}>
      <ScreenSizeProvider initialValue={ScreenSize.xxxlarge}>
        <AnalyticsProvider appName={appName} category={category} metadata={metadata}>
          <CurrentUserProvider user={user}>
            <CurrentRepositoryProvider repository={repo}>
              <FilesPageInfoProvider
                refInfo={refInfo}
                path={payload.path}
                action="tree"
                copilotAccessAllowed={payload.copilotAccessAllowed ?? false}
              >
                <CodeViewBannersProvider>
                  <AllShortcutsEnabledProvider allShortcutsEnabled={payload.allShortcutsEnabled}>
                    <FileQueryProvider>
                      <CurrentTreeProvider payload={payload.tree}>
                        <OverviewContent payload={payload} />
                      </CurrentTreeProvider>
                    </FileQueryProvider>
                  </AllShortcutsEnabledProvider>
                </CodeViewBannersProvider>
              </FilesPageInfoProvider>
            </CurrentRepositoryProvider>
          </CurrentUserProvider>
        </AnalyticsProvider>
      </ScreenSizeProvider>
    </AppPayloadContext.Provider>
  )
}

try{ ReposOverview.displayName ||= 'ReposOverview' } catch {}