import {Box, Flash, Button, Text, Link} from '@primer/react'
import {useCurrentRepository} from '@github-ui/current-repository'
import {migrateImmutableActionsPath, migrationWorkflowsPath} from '@github-ui/paths'
import {useRef, useState} from 'react'
import {ImmutableActionsMigrationDialog} from './ImmutableActionsMigrationDialog'
import {ImmutableActionsHasUnmigratableTagsDialog} from './ImmutableActionsHasUnmigratableTagsDialog'
import {isFullSemver} from '@github-ui/semver-util'

interface ImmutableActionsMigrationBannerProps {
  releaseTags: string[]
  initialMigrationStatus: string | null
}

export function ImmutableActionsMigrationBanner({
  releaseTags,
  initialMigrationStatus,
}: ImmutableActionsMigrationBannerProps) {
  const [isMigrationDialogOpen, setMigrationDialogOpen] = useState(false)
  const [isHasUnmigratableTagsDialogOpen, setHasUnmigratableTagsDialogOpen] = useState(false)
  const [migrationStatus, setMigrationStatus] = useState(initialMigrationStatus)

  const returnFocusRef = useRef(null)
  const repo = useCurrentRepository()
  const migratePath = migrateImmutableActionsPath({owner: repo.ownerLogin, repo: repo.name})
  const worfklowRunsPath = migrationWorkflowsPath({owner: repo.ownerLogin, repo: repo.name})
  // The query that populates releaseTags is limited to 1000 results
  const nonSemverTags = releaseTags.filter(tag => !isFullSemver(tag))

  const bannerTitle =
    migrationStatus === 'failed' ? 'Immutable Action Migration Failed' : 'Make your GitHub Action Immutable'
  const bannerText =
    migrationStatus === 'failed'
      ? 'One or more of your tags failed to be migrated to immutable action versions. You can try the migration again to migrate the remaining tags. '
      : 'Upgrade to a newer, more secure, and more reliable way of releasing and referencing GitHub Actions by recreating your existing tags as immutable package versions. '
  const buttonText = migrationStatus === 'failed' ? 'Retry migration' : 'Create immutable action'

  return (
    <Flash>
      <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2}}>
        {migrationStatus === 'in_progress' && (
          <>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                Your Action is being migrated to an immutable package version.
              </Box>
            </Box>
            <div>
              <Button as="a" href={worfklowRunsPath} data-testid="ia-migration-progress-button">
                View progress
              </Button>
            </div>
          </>
        )}
        {(migrationStatus === null || migrationStatus === 'failed') && (
          <>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
              <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Text sx={{fontWeight: 600}}>{bannerTitle}</Text>
              </Box>
              <Text sx={{fontSize: 0}}>
                {bannerText}
                <Link inline target="_blank" href="https://docs.github.com/actions">
                  Learn more about immutable actions.
                </Link>
              </Text>
            </Box>
            <div>
              <Button
                ref={returnFocusRef}
                data-testid="ia-migration-button-banner"
                onClick={() => {
                  if (nonSemverTags.length > 0) {
                    setHasUnmigratableTagsDialogOpen(true)
                  } else {
                    setMigrationDialogOpen(true)
                  }
                }}
              >
                {buttonText}
              </Button>
            </div>
          </>
        )}
      </Box>
      {isHasUnmigratableTagsDialogOpen && (
        <ImmutableActionsHasUnmigratableTagsDialog
          releaseTags={releaseTags}
          nonSemverTags={nonSemverTags}
          setMigrationDialogOpen={setMigrationDialogOpen}
          setHasUnmigratableTagsDialogOpen={setHasUnmigratableTagsDialogOpen}
        />
      )}
      {isMigrationDialogOpen && (
        <ImmutableActionsMigrationDialog
          repoName={repo.name}
          releaseTags={releaseTags}
          nonSemverTags={nonSemverTags}
          migratePath={migratePath}
          setMigrationDialogOpen={setMigrationDialogOpen}
          setHasUnmigratableTagsDialogOpen={setHasUnmigratableTagsDialogOpen}
          setMigrationStatus={setMigrationStatus}
        />
      )}
    </Flash>
  )
}

try{ ImmutableActionsMigrationBanner.displayName ||= 'ImmutableActionsMigrationBanner' } catch {}